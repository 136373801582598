<template>
  <div>
    <!--Title Bar-->
    <title-bar v-if="actionSection === 1" :title-value="'Alerts'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card v-else flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-exclamation-triangle</v-icon>
        <v-toolbar-title class="pl-2">Alerts</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card max-width="400px">
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <!--Clear Filter Button -->
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>

                <!--Date-->
                <v-col cols="12" sm="12">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_date"
                        label="Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        required
                        @blur="date1 = $_parseDate(d_date)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!--Type-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="actionTypesStore.actionTypesValueList"
                    item-text="d_name"
                    item-value="id"
                    label="Type"
                    placeholder=" "
                    persistent-placeholder
                    v-model="fk_actionTypeID"
                    key="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <!--Status-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.actionStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <!--Submit Entry-->
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-toolbar>

    <!--Pagination  -->
    <pagination
      :show="actionsStore.actions.data && actionsStore.actions.data.length > 0"
      :showTotal="true"
      :currentPage="actionsStore.actions.current_page"
      :lastPage="actionsStore.actions.last_page"
      :total="actionsStore.actions.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-basic
        :show="actionsStore.actions.data && actionsStore.actions.data.length > 0"
        :list-data="actionsStore.actions.data"
        :title="'Issues'"
        :section="4.1"
        @open-entry="openEntry($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import ListBasic from "@/components/ListBasic";

const { mapFields } = createHelpers({
  getterType: "actionsStore/getFilterField",
  mutationType: "actionsStore/updateFilterField"
});

export default {
  name: "ActionList",
  components: {
    TitleBar,
    Pagination,
    ListBasic
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      actionsStore: state => state.actionsStore,
      actionTypesStore: state => state.actionTypesStore,
      progressStore: state => state.progressStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "page",
      "section",

      "fk_providerID",
      "fk_clientID",
      "fk_teacherID",
      "fk_waitingListID",
      "fk_userID",
      "fk_actionTypeID",
      "d_date",
      "date1",
      "f_status"
    ])
  },
  props: {
    /* All List = 1, Provider = 2, Client = 3, Teacher = 4, WaitingList = 5 */
    actionSection: {
      type: Number,
      required: false
    }
  },
  created() {
    this.initialize();
  },
  watch: {
    date1() {
      this.d_date = this.$_formatDate(this.date1);
    }
  },
  data() {
    return {
      filterMenu: false,
      menu: false
    };
  },
  methods: {
    async initialize() {
      if (this.actionSection === 1) {
        this.$store.dispatch("progressStore/set", true);
      }

      /* Run function to determine if user is switching sections */
      await this.changeSectionCheck();

      /* Provider Section */
      if (this.actionSection === 2) {
        /* Find provider detail */
        const provider = this.$store.getters["providersStore/getProvider"];
        /* Hard code provder ID to restrict by provider */
        this.fk_providerID = provider.id;
        this.fk_clientID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = "";
      } else if (this.actionSection === 3) {
      /* Client Section */
        /* Grab client from url parameter */
        // await this.$store.dispatch('clientsStore/read', this.$route.params.clientUUID);
        /* Find client detail */
        const client = this.$store.getters["clientsStore/getClient"];
        /* Hard code client ID to restrict by client */
        this.fk_providerID = "";
        this.fk_clientID = client.id;
        this.fk_teacherID = "";
        this.fk_waitingListID = "";
      } else if (this.actionSection === 4) {
        /* Find teacher detail */
        const teacher = this.$store.getters["teachersStore/getTeacher"];
        /* Hard code teacher ID to restrict by teacher */
        this.fk_providerID = "";
        this.fk_clientID = "";
        this.fk_teacherID = teacher.id;
        this.fk_waitingListID = "";
      } else if (this.actionSection === 5) {
        /* Find waiting list detail */
        const waitingListItem = this.$store.getters["waitingListStore/getWaitingListItem"];
        /* Hard code waitinglist item ID to restrict by waitinglist item */
        this.fk_providerID = "";
        this.fk_clientID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = waitingListItem.id;
      } else {
      /* All */
        this.providerID = "";
        this.fk_clientID = "";
        this.fk_teacherID = "";
        this.fk_waitingListID = "";
      }

      /* Grab actions list */
      const filters = this.$store.getters["actionsStore/getFilters"];
      await this.$store.dispatch("actionsStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists() {
      let data;
      /* Provider */
      if (this.actionSection === 2) {
        data = {
          f_type: 1
        };
      } else if (this.actionSection === 3) {
      /* Client */
        data = {
          f_type: 2
        };
      } else if (this.actionSection === 4) {
      /* Teacher */
        data = {
          f_type: 3
        };
      } else if (this.actionSection === 5) {
      /* Waiting List */
        data = {
          f_type: 4
        };
      }
      await this.$store.dispatch("actionTypesStore/valueList", data);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.fk_userID = "";
      this.fk_actionTypeID = "";
      this.date1 = "";
      this.d_note = "";
      this.f_showOnDetail = "";
      this.f_status = "";
      this.page = 1;
    },

    async changeSectionCheck() {
      await this.loadValueLists();
      if (this.actionSection !== this.section) {
        this.clearFilterColumns();
        this.$store.dispatch("actionsStore/clearActions");
      }
      /* Save section in store */
      this.section = this.actionSection;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (this.d_date || this.fk_actionTypeID || this.f_status || this.f_status === 0) {
        return true;
      }
      return false;
    },

    async openEntry(id) {
      await this.loadValueLists();
      await this.$store.dispatch("actionsStore/entry", id);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
